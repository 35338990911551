<template>
  <div class="th-pt-24">
    <v-slider
      :max="input.max || 10"
      :min="input.min || 0"
      v-model="model"
      :thumb-size="24"
      thumb-label="always"
    ></v-slider>
  </div>
</template>

<script>
export default {
  name: "SliderInput",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: Number,
  },
  data: () => ({
    model: 5,
  }),
  created() {
    this.model = this.value ?? 5;
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.model = newValue ?? 5;
    },
  },
};
</script>

<style lang="scss" scoped></style>
